import type { ObjectValues } from "~/@types/generics"

export const QUERY_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
} as const

export const QUERY_METHODS_KEYS = Object.values(QUERY_METHODS)
export type QueryMethodsKeys = keyof typeof QUERY_METHODS

// Query routes
export const QUERY_ROUTES = {
  ALGOLIA_RECOMMENDATIONS: "cache/algolia-recommendations",
  SHOPIFY_NEWSLETTER: "shopify/customers/newsletter",
  SHOPIFY_CUSTOMER_LOCALE: "shopify/customers/locale",
  SHOPIFY_CUSTOMER_METAFIELDS: "shopify/customers/metafields",
  SHOPIFY_CUSTOMER_TAGS: "shopify/customers/tags",
  SHOPIFY_CUSTOMER_UPDATE: "shopify/customers/update",
  SHOPIFY_DRAFT_ORDER_CREATE: "shopify/orders/draft-order-create",
  SHOPIFY_ADMIN_ORDER_GET: "shopify/order",
  SHOPIFY_ADMIN_STOCK: "shopify/stock",
  RECAPTCHA: "verify-recaptcha",
  CONTACT_FORM: "contact-form",
  BACK_IN_STOCK: "back-in-stock",
  SHOPIFY_MULTIPASS: "multipass",
} as const

// Multipass
export const SHOPIFY_MULTIPASS_PARAMS = {
  action: QUERY_ROUTES.SHOPIFY_MULTIPASS,
  method: QUERY_METHODS.POST,
}

export const QUERY_ROUTES_KEYS = Object.values(QUERY_ROUTES)
export type QueryRoutesKeys = ObjectValues<typeof QUERY_ROUTES_KEYS>

export const SHOPIFY_NEWSLETTER_PARAMS = {
  action: QUERY_ROUTES.SHOPIFY_NEWSLETTER,
  method: QUERY_METHODS.POST,
}

export const SHOPIFY_CUSTOMER_LOCALE_PARAMS = {
  action: QUERY_ROUTES.SHOPIFY_CUSTOMER_LOCALE,
  method: QUERY_METHODS.PUT,
}

export const SHOPIFY_CUSTOMER_METAFIELDS_PARAMS = {
  action: QUERY_ROUTES.SHOPIFY_CUSTOMER_METAFIELDS,
  method: QUERY_METHODS.PUT,
}

export const SHOPIFY_CUSTOMER_TAGS_PARAMS = {
  action: QUERY_ROUTES.SHOPIFY_CUSTOMER_TAGS,
  method: QUERY_METHODS.PUT,
}

export const SHOPIFY_CUSTOMER_UPDATE_PARAMS = {
  action: QUERY_ROUTES.SHOPIFY_CUSTOMER_UPDATE,
  method: QUERY_METHODS.PUT,
}

export const SHOPIFY_DRAFT_ORDER_CREATE_PARAMS = {
  action: QUERY_ROUTES.SHOPIFY_DRAFT_ORDER_CREATE,
  method: QUERY_METHODS.POST,
}

export const SHOPIFY_ADMIN_ORDER_GET_PARAMS = {
  action: QUERY_ROUTES.SHOPIFY_ADMIN_ORDER_GET,
  method: QUERY_METHODS.GET,
}

export const RECAPTCHA_PARAMS = {
  action: QUERY_ROUTES.RECAPTCHA,
  method: QUERY_METHODS.POST,
}

export const CONTACT_FORM_PARAMS = {
  action: QUERY_ROUTES.CONTACT_FORM,
  method: QUERY_METHODS.POST,
}

export const BACK_IN_STOCK_PARAMS = {
  action: QUERY_ROUTES.BACK_IN_STOCK,
  method: QUERY_METHODS.POST,
}

export const SHOPIFY_ADMIN_STOCK_PARAMS = {
  action: QUERY_ROUTES.SHOPIFY_ADMIN_STOCK,
  method: QUERY_METHODS.GET,
}

export const ALGOLIA_RECOMMENDATIONS_PARAMS = {
  action: QUERY_ROUTES.ALGOLIA_RECOMMENDATIONS,
  method: QUERY_METHODS.GET,
}

export const MARKETS_SOCLOZ_SHOPIFY = {
  FR: "FR",
  US: "US",
  GB: "UK",
  CH: "CH",
  BE: "FR",
} as const

export type MARKETS_SOCLOZ_SHOPIFY_KEYS = keyof typeof MARKETS_SOCLOZ_SHOPIFY

export const MAX_EVENT_SEND_NUMBER = 900
